<template>
  <client-only v-if="carouselItems.length">
    <v-row no-gutters>
      <v-col>
        <v-carousel
          v-model="carouselModel"
          :show-arrows="false"
          hide-delimiter-background
          :hide-delimiters="true"
          :height="carouselHeight"
        >
          <v-carousel-item v-for="item in carouselItems" :key="item.name">
            <v-sheet class="banner-wrapper">
              <component :is="item" />
            </v-sheet>
          </v-carousel-item>
        </v-carousel>

        <ul
          v-if="showDelimiters"
          class="library-banner-custom-delimiter d-flex justify-center mt-4"
        >
          <li
            v-for="(item, i) in carouselItems"
            :key="item.name"
            :class="{ active: i === carouselModel }"
            class="mx-1"
            @click="carouselModel = i"
          />
        </ul>
      </v-col>
    </v-row>
  </client-only>
</template>

<script>
import { mapGetters } from 'vuex';

import BrandsEnum from '@shared/enums/BrandsEnum.mjs';

// import LibraryGuestBanner from '@/components/views/LibraryBanner/LibraryGuestBanner/LibraryGuestBanner.vue';
// import RecentBanner from '@/components/views/LibraryBanner/RecentBanner/RecentBanner.vue';
import SuggestedBanner from '@/components/views/LibraryBanner/SuggestedBanner/SuggestedBanner.vue';

export default {
  name: 'LibraryBanner',
  data() {
    return {
      carouselModel: null
    };
  },
  computed: {
    ...mapGetters('UserStore', ['isGuestUser']),
    ...mapGetters('RecentBookStore', ['getLastRecentBookItem']),
    ...mapGetters('AssessmentStore', ['isSuggestedAssessmentBlockVisible']),
    // ...mapGetters('ContextStore', [
    //   'isPurchaseEnabled',
    //   'isLimitedGuestAccess'
    // ]),
    showDelimiters() {
      return this.carouselItems.length > 1;
    },
    carouselHeight() {
      if (this.$store.getters['ContextStore/brand'] === BrandsEnum.OCEAN) {
        return 144;
      }
      const narrow = this.$store.getters['MediaDetectorStore/mediaSize'].narrow;
      return narrow ? 220 : 256;
    },
    carouselItems() {
      const banners = [];
      if (this.$store.getters['ContextStore/brand'] === BrandsEnum.OCEAN) {
        return banners;
      }
      // don't remove, maybe return in the future
      // if (
      //   this.isGuestUser &&
      //   (this.isLimitedGuestAccess || this.isPurchaseEnabled)
      // ) {
      //   banners.push(LibraryGuestBanner);
      // }
      if (
        !this.isGuestUser &&
        this.isSuggestedAssessmentBlockVisible &&
        this.isAssessmentBannerVisible
      ) {
        banners.push(SuggestedBanner);
      }
      // don't remove, maybe return in the future
      // if (this.getLastRecentBookItem) {
      //   banners.push(RecentBanner);
      // }
      return banners;
    },
    isAssessmentBannerVisible() {
      return this.$store.getters['UserStore/isSuggestedBannerVisible'];
    },
    isAssessmentAvailable() {
      return this.$store.getters['ProgressStore/getIsAssessmentAvailable'];
    }
  }
};
</script>

<style lang="less" src="./LibraryBanner.less"></style>
